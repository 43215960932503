<!--  -->
<template>
    <div class=''>

        <div>
            <el-col :span="5">
                <div class="calender-scroll" align="center">
                    <div id="TendencySingoCalendar" :style="'width:220px;height:1200px'">
                    </div>
                </div>
            </el-col>
            <el-col :span="19">
                <el-col :span="2">
                    <div class="blank"></div>
                </el-col>
                <el-button-group>
                    <el-button size="small" autofocus="true" @click="getWeekDay()">本周</el-button>
                    <el-button size="small" @click="getMonth()">本月</el-button>
                    <el-button size="small" @click="getYear()">本年</el-button>
                </el-button-group>

                <!-- <div class="mr20" v-for="(item, index) in bdTimeData" :key="index" @click="handleBdTime(index)"
                    :class="{ timeStyle: timeIndex == index }">{{ item.title }}
                </div> -->

                <div align="center">
                    <div id="TendencySingoChart" :style="'width:' + (screenWidth * 19 / 24 - 320) + 'px; height:550px'">
                    </div>
                </div>
            </el-col>
        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';


import { Console } from 'console';
import * as echarts from 'echarts'; //是calender随机的关键
export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            screenHeight: window.innerHeight,
            screenWidth: window.innerWidth,
            formerday: '',
            latterday: '',
            bdTimeIndex: "1",//默认显示本月数据
            bdScopeTime: "month",
            bdTimeData: [
                { title: "本周", value: "week" },
                { title: "本月", value: "month" },
                { title: "全年", value: "year" }
            ],
            option: {},
            Calendar_option: {}

        };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        TimeProcess(time) {
            let y = time.getFullYear();
            let MM = time.getMonth() + 1;
            MM = MM < 10 ? ('0' + MM) : MM;
            let d = time.getDate();
            d = d < 10 ? ('0' + d) : d;
            return y + '-' + MM + '-' + d
        },
        handleBdTime(index) {
            this.bdTimeIndex = index;
            this.bdScopeTime = value
            if (bdTimeIndex == 0) {
                this.getWeekDay()
            }
            else if (bdTimeIndex == 1) {
                this.getMonth()
            }
            else {
                this.getYear()
            }
        },
        getWeekDay() {
            var today = new Date();
            var formerday = new Date(today);
            formerday.setDate(today.getDate() - 6);
            this.latterday = this.TimeProcess(today)
            this.formerday = this.TimeProcess(formerday)
            console.log(this.formerday)
            console.log(this.latterday)
            this.title = '本周'
            this.initSingo()
            this.UpdateHolter_getConclusion(this.formerday, this.latterday)
        },
        getMonth() {
            // 本月指的是近30天的趋势
            var date = new Date()
            var MonthfirstDay = new Date(date);
            MonthfirstDay.setDate(date.getDate() - 30);
            this.latterday = this.TimeProcess(date)
            this.formerday = this.TimeProcess(MonthfirstDay)
            console.log(this.formerday)
            console.log(this.latterday)
            this.title = '本月'
            this.initSingo()
            this.UpdateHolter_getConclusion(this.formerday, this.latterday)
        },
        getYear() {
            var date = new Date(), y = date.getFullYear();
            var YearfirstDay = new Date(y, 0);
            var YearlastDay = new Date(y + 1, 0, 0);
            this.latterday = this.TimeProcess(YearlastDay)
            this.formerday = this.TimeProcess(YearfirstDay)
            console.log(this.formerday)
            console.log(this.latterday)
            this.title = '本年'
            this.initSingo()
            this.UpdateHolter_getConclusion(this.formerday, this.latterday)
        },
        initSingo() {
            this.myChart = this.$echarts.init(document.getElementById("TendencySingoChart"));
            this.myChart.setOption(this.option);
            this.myChart.showLoading({
                text: '请稍等, 正在为您加载数据',
                zlevel: 0,
                // 字体大小。
                fontSize: 20,
                // 是否显示旋转动画（spinner）。
                showSpinner: true,
                // 旋转动画（spinner）的半径。
                spinnerRadius: 16,
                // 旋转动画（spinner）的线宽。
                lineWidth: 8,
            })

        },
        initCalendar() {
            this.CalendarChart = this.$echarts.init(document.getElementById("TendencySingoCalendar"));
            this.CalendarChart.setOption(this.Calendar_option);
            this.CalendarChart.showLoading({
                text: '',
                zlevel: 0,
                // 字体大小。
                fontSize: 20,
                // 是否显示旋转动画（spinner）。
                showSpinner: true,
                // 旋转动画（spinner）的半径。
                spinnerRadius: 16,
                // 旋转动画（spinner）的线宽。
                lineWidth: 8,
            })
        },
        getVirtualData(year) {
            const date = +echarts.time.parse(year + '-01-01');
            const end = +echarts.time.parse(year + '-12-31');
            const dayTime = 3600 * 24 * 1000;
            const data = [];
            for (let time = date; time < end; time += dayTime) {
                data.push([
                    echarts.time.format(time, '{yyyy}-{MM}-{dd}', false),
                    Math.floor(Math.random() * 10000)
                ]);
            }
            return data;
        },
        UpdateHolter_getConclusion(former, latter) {
            // 根据uid、起止日期、返回所有静态心电结论
            this.$http({
                url: this.$http.adornUrl("/personal/dynamicecg/getConclusionByTime"),
                method: "post",
                data: this.$http.adornData({
                    uid: this.$store.state.women_baby.uid, startTime: former, endTime: latter
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log("看看数据");
                    this.reverseconclusionData = data.conclusions
                    this.conclusionData = this.reverseconclusionData.reverse()
                    if (this.conclusionData[this.conclusionData.length - 1] == ',') {
                        this.conclusionData = "[" + this.conclusionData.substring(0, this.conclusionData.length - 1) + "]";
                    }
                    //JSON格式转换
                    this.xinlv = []
                    this.xdgs = []
                    this.xdgh = []
                    this.xlbq = []
                    this.dxtb = []
                    this.fxzb = []
                    this.jjxzb = []
                    this.sxzb = []
                    this.ssxxdgs = []
                    this.sxxdgs = []
                    this.fxyb = []
                    this.sxyb = []
                    this.fc = []
                    this.timelist = []
                    for (let i = 0; i < this.conclusionData.length; i++) {
                        var thisConclusion = JSON.parse(this.conclusionData[i])
                        for (let j = 0; j < thisConclusion.length; j++) {
                            this.xinlv.push(thisConclusion[j].xinlv)
                            this.xdgs.push(thisConclusion[j].xdgs)
                            this.xdgh.push(thisConclusion[j].xdgh)
                            this.xlbq.push(thisConclusion[j].xlbq)
                            this.dxtb.push(thisConclusion[j].dxtb)
                            this.fxzb.push(thisConclusion[j].fxzb)
                            this.jjxzb.push(thisConclusion[j].jjxzb)
                            this.sxzb.push(thisConclusion[j].sxzb)
                            this.ssxxdgs.push(thisConclusion[j].ssxxdgs)
                            this.sxxdgs.push(thisConclusion[j].sxxdgs)
                            this.fxyb.push(thisConclusion[j].fxyb)
                            this.sxyb.push(thisConclusion[j].sxyb)
                            this.fc.push(thisConclusion[j].fc)
                            this.timelist.push(thisConclusion[j].time)

                        }
                    }
                    // 更新echarts数据
                    // 先清空之前的
                    this.option.title.text = [];
                    this.option.xAxis[0].data = [];
                    this.option.series[0].data = [];
                    this.option.series[1].data = [];
                    this.option.series[2].data = [];
                    this.option.series[3].data = [];
                    this.option.series[4].data = [];
                    this.option.series[5].data = [];
                    this.option.series[6].data = [];
                    this.option.series[7].data = [];
                    this.option.series[8].data = [];
                    this.option.series[9].data = [];
                    this.option.series[10].data = [];
                    this.option.series[11].data = [];
                    this.option.series[12].data = [];
                    // 再设置新的
                    this.option.title.text.push(this.title + '趋势图')
                    this.option.xAxis[0].data = this.timelist.map(function (str) {
                        return str.replace(' ', '\n');
                    })
                    this.option.series[0].data = this.xinlv;
                    this.option.series[1].data = this.xdgs;
                    this.option.series[2].data = this.xdgh;
                    this.option.series[3].data = this.xlbq;
                    this.option.series[4].data = this.dxtb;
                    this.option.series[5].data = this.fxzb;
                    this.option.series[6].data = this.jjxzb;
                    this.option.series[7].data = this.sxzb;
                    this.option.series[8].data = this.ssxxdgs;
                    this.option.series[9].data = this.sxxdgs;
                    this.option.series[10].data = this.jjxzb;
                    this.option.series[11].data = this.sxyb;
                    this.option.series[12].data = this.fc;

                    this.myChart.hideLoading();
                    this.myChart.setOption(this.option);


                } else {
                    this.$message.error(data.msg);
                }
            });

        },
        Calendar_queryCount() {
            var date = new Date(), y = date.getFullYear();
            var YearfirstDay = new Date(y, 0);
            var YearlastDay = new Date(y + 1, 0, 0);
            this.YearlastDay = this.TimeProcess(YearlastDay)
            this.YearfirstDay = this.TimeProcess(YearfirstDay)
            this.$http({
                url: this.$http.adornUrl("/personal/dynamicecg/getConclusionCountByTime"),
                method: "post",
                data: this.$http.adornData({
                    uid: this.$store.state.women_baby.uid, startTime: this.YearfirstDay, endTime: this.YearlastDay
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log("看看数据");
                    console.log(data)
                    this.CalendarCount = data.conclusions  // 一整条记录
                    var Count_dxtb = new Array(this.CalendarCount.length).fill(0)
                    var Count_eachSegment = new Array(this.CalendarCount.length).fill(0)  //每一次测试的小段数
                    var score_abnormal = new Array(this.CalendarCount.length)
                    var createTime = new Array(this.CalendarCount.length)
                    var score_time = new Object();
                    var all_score_time = []
                    for (let i = 0; i < this.CalendarCount.length; i++) {
                        var score_time = new Object();
                        var thisCalendarCount = JSON.parse(this.CalendarCount[i])
                        Count_eachSegment[i] = thisCalendarCount.length
                        for (let j = 0; j < thisCalendarCount.length; j++) {
                            Count_dxtb[i] = Count_dxtb[i] + Number(thisCalendarCount[j].xdgs) + Number(thisCalendarCount[j].xdgh)
                                + Number(thisCalendarCount[j].xlbq) + Number(thisCalendarCount[j].dxtb) + Number(thisCalendarCount[j].fxzb)
                                + Number(thisCalendarCount[j].jjxzb) + Number(thisCalendarCount[j].sxzb) + Number(thisCalendarCount[j].ssxxdgs)
                                + Number(thisCalendarCount[j].sxxdgs) + Number(thisCalendarCount[j].fxyb) + Number(thisCalendarCount[j].sxyb)
                                + Number(thisCalendarCount[j].fc)
                        }
                        score_abnormal[i] = Count_dxtb[i] / Count_eachSegment[i]
                        createTime[i] = thisCalendarCount[0].time.substring(0, 10)
                        score_time.time = createTime[i]
                        score_time.score = score_abnormal[i]
                        all_score_time.push(score_time)
                    }
                    // console.log(all_score_time)
                    // merge代表合并的time与异常值求和
                    var merge = all_score_time.reduce((obj, item) => {
                        let find = obj.find(i => i.time === item.time)
                        let _d = {
                            ...item,
                            count: 1
                        }
                        find ? (find.score += item.score, find.count++) : obj.push(_d)
                        return obj
                    }, [])
                    console.log(merge)
                    // avg_merge代表time与异常值求平均
                    var avg_merge = Object.keys(merge).map(function (k) {
                        const item = merge[k];
                        return {
                            time: item.time,
                            count: item.count,
                            avgScore: item.score / item.count,
                        }
                    })
                    var len = avg_merge.length
                    var CalendarFrequent = []
                    var CalendarAbnormal = []
                    for (let i = 0; i < len; i++) {
                        CalendarFrequent.push([avg_merge[i].time, avg_merge[i].count])
                        CalendarAbnormal.push([avg_merge[i].time, avg_merge[i].avgScore])
                    }
                    this.CalendarFrequent = CalendarFrequent
                    this.CalendarAbnormal = CalendarAbnormal
                    console.log(CalendarAbnormal)
                    var CalenderlastMonth = this.CalendarFrequent[0][0].substring(5, 7)
                    var CalenderlastMonthDay = new Date(2022, CalenderlastMonth, 0);
                    this.CalenderlastMonthDay = this.TimeProcess(CalenderlastMonthDay)

                    // 更新参数
                    this.Calendar_option.series[0].data = []
                    this.Calendar_option.series[1].data = []
                    this.Calendar_option.series[2].data = []

                    this.Calendar_option.series[0].data = this.CalendarFrequent
                    this.Calendar_option.series[1].data = this.CalendarAbnormal
                    this.Calendar_option.series[2].data = this.getVirtualData(2023)
                    this.CalendarChart.hideLoading()
                    this.CalendarChart.setOption(this.Calendar_option);
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg);
                }
            });
        }
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        var today = new Date()
        this.year = today.getFullYear()
        this.cellSize =[22,20]
        this.option = {
            title: {
                text: '',
                left: 'center',
                textStyle: {
                    fontSize: 15
                }
            },
            animation: false,
            grid: {
                top: 70,
                bottom: 80,
                left: 45,
                right: 50
            },
            toolbox: {
                feature: {
                    restore: {},
                    saveAsImage: {},
                    magicType: {
                        type: ['line', 'bar', 'stack'],
                        seriesIndex: {
                            line: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                            bar: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                            stack: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                        }

                    }
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                    animation: false,
                    label: {
                        backgroundColor: '#505765'
                    }
                }
            },
            legend: {
                data: ['心率', '心动过速', '心动过缓', '心律不齐', '窦性停搏', '房性早搏', '交界性早搏', '室性早搏', '室上性心动过速', '室性心动过速', '交界性逸博', '室性逸博', '房颤'],
                left: 'center',
                width: '70%',
                top: 20
            },
            dataZoom: [
                {
                    show: true,
                    realtime: true,
                    start: 0,
                    end: 30
                },
                {
                    type: 'inside',
                    realtime: true,
                    // start: 65,
                    // end: 85
                }
            ],
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: false,
                    axisLine: { onZero: false, },
                    // prettier-ignore
                    data: []
                }
            ],
            yAxis: [
                {
                    name: '心率(次/分钟)',
                    type: 'value',
                },
                {
                    name: '异常项疑似度(%)',
                    alignTicks: true,
                    type: 'value',
                }
            ],
            series: [
                {
                    name: '心率',
                    type: 'line',
                    color: 'firebrick',
                    label: {
                        show: true
                    },
                    // areaStyle: {},
                    lineStyle: {
                        width: 3
                    },
                    symbol: 'triangle',
                    symbolSize: 10,
                    emphasis: {   //折线图的高亮状态。
                        focus: 'series'
                    },
                    markArea: {
                        silent: true,
                        itemStyle: {
                            opacity: 0.3
                        },
                        data: [
                            [
                                {
                                    yAxis: 100
                                },
                                {
                                    yAxis: 200
                                }
                            ],

                        ]
                    },
                    // prettier-ignore
                    data: []
                },
                {
                    name: '心动过速',
                    type: 'bar',
                    stack: 'total',
                    yAxisIndex: 1,
                    // color: '#66b7f3 ',
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    // prettier-ignore
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '心动过缓',
                    type: 'bar',
                    stack: 'total',
                    // color: '#0c84c6',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '心律不齐',
                    type: 'bar',
                    stack: 'total',
                    // color: '#2455a4 ',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '窦性停搏',
                    type: 'bar',
                    stack: 'total',
                    // color: ' #D2B4DE  ',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '房性早搏',
                    type: 'bar',
                    stack: 'total',
                    // color: ' #76448A   ',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '交界性早搏',
                    type: 'bar',
                    stack: 'total',
                    // color: '#F5B7B1 ',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '室性早搏',
                    type: 'bar',
                    stack: 'total',
                    // color: '#CB4335   ',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '室上性心动过速',
                    type: 'bar',
                    stack: 'total',
                    // color: '#F1C40F ',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '室性心动过速',
                    type: 'bar',
                    stack: 'total',
                    // color: '#d45e7e',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '交界性逸博',
                    type: 'bar',
                    stack: 'total',
                    color: ' #fc5252',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '室性逸博',
                    type: 'bar',
                    stack: 'total',
                    color: '#F0B27A ',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '房颤',
                    type: 'bar',
                    stack: 'total',
                    color: '#A6ACAF ',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },

            ]
        },
        this.Calendar_option = {
            // tooltip: {},
            visualMap: [
                {
                    min: -10,
                    max: 20,
                    seriesIndex: [0],
                    inRange: {
                        color: ['#FFFFFF', '#e7efff', '#b2caf9'],
                    },
                    text: ['多', '检测次数：少'],
                    orient: 'horizontal',
                    top: 0,
                    itemHeight: 80,
                    itemWidth: 15,
                    textStyle: {
                        width: 80,
                        height: 100,
                        overflow: "break"
                    }
                },
                {
                    pieces: [
                        { value: 0, label: '健康' },
                        // {value:0,label:'健康'},
                        { gt: 0, lte: 0.5, label: '亚健康' },
                        { gt: 0.5, label: '异常' }
                    ],
                    textGap: 5,
                    itemGap: 5,
                    // text: ['高', '疑似度：低'],
                    seriesIndex: [1],
                    itemSymbol: 'circle',
                    left: 'center',
                    orient: 'horizontal',
                    top: 25,
                    itemHeight: 80,
                    itemWidth: 12,
                    textStyle: {
                        width: 80,
                        height: 100,
                        overflow: "break"
                    },
                    inRange: {
                        color: ['#FFFFFF', '#F1948A ', '#B03A2E'],

                    },
                },

            ],
            calendar: {
                orient: 'vertical',
                left: 50,
                top: 110,
                cellSize: this.cellSize ,
                // range: ['2022-07-01', '2022-08-31'],//返回有数据的月份
                range:this.year,
                monthLabel: {
                    nameMap: 'ZH',    // v5.2.2 及以后版本
                    margin: 15,
                },
                dayLabel: {
                    nameMap: 'ZH'    // v5.2.2 及以后版本
                },
                yearLabel: {
                    color: '#424949',
                },
            },
            series: [
                {
                    type: 'heatmap',
                    coordinateSystem: 'calendar',
                    data: []
                    // this.arrayCalendar
                },
                {
                    type: 'scatter',
                    coordinateSystem: 'calendar',
                    symbolSize: function (val) {  //标志大小随着值变化
                        return val[1] * 12
                    },
                    data: []
                },
                {
                    id: 'label',
                    type: 'scatter',
                    coordinateSystem: 'calendar',
                    symbolSize: 0,
                    label: {
                        show: true,
                        formatter: function (params) {
                            return echarts.time.format(params.value[0], '{dd}', false);
                        },
                        // offset: [-cellSize[0] / 2 + 10, -cellSize[1] / 2 + 10],
                        fontSize: 8
                    },
                    data: []
                },
            ]

        }


    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        // 默认加载本周数据
        this.getWeekDay()
        this.initCalendar()
        this.Calendar_queryCount()
        window.onresize = () => {
            return (() => {
                window.screenHeight = window.innerHeight;
                this.screenHeight = window.screenHeight;
                window.screenWidth = window.innerWidth;
                this.screenWidth = window.screenWidth;
            })();
        };

    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.calender-scroll {
    overflow-y: scroll;
    height: 550px;
}

.calender-scroll::-webkit-scrollbar {
    /*滚动条高宽度*/
    width: 8px;
    height: 4px;
}

.calender-scroll::-webkit-scrollbar-thumb {
    /*滚动条滑块*/
    border-radius: 3px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #c7c4c4;
}

.calender-scroll::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 10px;
    background: #ededed;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, .1);
}

.timeStyle {
    color: #409EFF;
}

.blank {
    border-radius: 4px;
    height: 20px;
}
</style>